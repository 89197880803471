import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import { delay, exhaustMap, filter, from, map, switchMap, tap } from 'rxjs';
import { DashboardCacheType } from '../../../../model/dashboard';
import { DateRange, dateRanges } from '../../../../model/dateRange';
import { AdminActions } from '../../../../state/admin/actions';
import { adminFeature } from '../../../../state/admin/feature';
import { NodesActions } from '../../nodes/state/nodes.actions';
import { DashboardService } from '../services/dashboard.service';
import { AuthActions } from './../../../../state/auth/actions';
import { DashboardActions } from './dashboard.actions';
import { dashboardFeature } from './dashboard.feature';

@Injectable()
export class DashboardEffects {
  private readonly savedConfigsPersistentKey = 'sigrow:savedConfigs';

  readings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        AdminActions.activeLocationConfigRetrived,
        DashboardActions.dateRangeChanged,
      ),
      concatLatestFrom(() => [
        this.store.select(adminFeature.selectActiveLocation),
        this.store.select(dashboardFeature.selectDateRange),
      ]),
      filter((values) => values.every((v) => !!v)),
      switchMap(([, activeLocation, dateRange]) =>
        // TODO: change that to ensureReadingsCache
        this.dashboardMng.loadReadings(activeLocation!, dateRange).pipe(
          map((res) =>
            DashboardActions.cacheUpdated({
              key: DashboardCacheType.readings,
              value: res.datasets,
            }),
          ),
        ),
      ),
    );
  });

  points$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardActions.dateRangeChanged,
        DashboardActions.togglePoint,
        DashboardActions.togglePointVariable,
      ),
      concatLatestFrom(() => [
        this.store.select(dashboardFeature.selectDateRange),
        this.store.select(dashboardFeature.selectPoints),
        this.store.select(
          dashboardFeature.selectCacheByType(DashboardCacheType.points),
        ),
      ]),
      exhaustMap(([, dateRange, points, cache]) =>
        this.doEnsureCache(DashboardCacheType.points, () =>
          this.dashboardMng.ensurePointsCache(dateRange, points, cache),
        ),
      ),
    );
  });

  customPoints$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardActions.dateRangeChanged,
        DashboardActions.toggleCustomPoint,
      ),
      concatLatestFrom(() => [
        this.store.select(dashboardFeature.selectDateRange),
        this.store.select(dashboardFeature.selectCustomPoints),
        this.store.select(
          dashboardFeature.selectCacheByType(DashboardCacheType.customPoints),
        ),
      ]),
      exhaustMap(([, dateRange, customPoints, cache]) =>
        this.doEnsureCache(DashboardCacheType.customPoints, () =>
          this.dashboardMng.ensureCustomPointsCache(
            dateRange,
            customPoints,
            cache,
          ),
        ),
      ),
    );
  });

  recognitionReadings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardActions.dateRangeChanged,
        DashboardActions.toggleRecognition,
        DashboardActions.toggleRecognitionVariable,
      ),
      concatLatestFrom(() => [
        this.store.select(dashboardFeature.selectDateRange),
        this.store.select(dashboardFeature.selectRecognitions),
        this.store.select(
          dashboardFeature.selectCacheByType(
            DashboardCacheType.recognitionReadings,
          ),
        ),
      ]),
      exhaustMap(([, dateRange, recognitions, cache]) =>
        this.doEnsureCache(DashboardCacheType.recognitionReadings, () =>
          this.dashboardMng.ensureRecognitionReadingsCache(
            dateRange,
            recognitions,
            cache,
          ),
        ),
      ),
    );
  });

  cameraImages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardActions.dateRangeChanged,
        DashboardActions.toggleDevice,
        DashboardActions.toggleImagesDisplay,
      ),
      concatLatestFrom(() => [
        this.store.select(dashboardFeature.selectImagesDisplayed),
        this.store.select(dashboardFeature.selectDateRange),
        this.store.select(dashboardFeature.selectDevices),
        this.store.select(
          dashboardFeature.selectCacheByType(DashboardCacheType.images),
        ),
      ]),
      filter(([, imagesDisplayed]) => imagesDisplayed),
      exhaustMap(([, , dateRange, devices, cache]) =>
        this.doEnsureCache(DashboardCacheType.images, () =>
          this.dashboardMng.ensureImagesCache(dateRange, devices, cache),
        ),
      ),
    );
  });

  cameraAreas$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardActions.dateRangeChanged,
        DashboardActions.toggleDevice,
        DashboardActions.toggleImagesDisplay,
      ),
      concatLatestFrom(() => [
        this.store.select(dashboardFeature.selectImagesDisplayed),
        this.store.select(dashboardFeature.selectDateRange),
        this.store.select(dashboardFeature.selectRecognitions),
        this.store.select(
          dashboardFeature.selectCacheByType(
            DashboardCacheType.recognitionAreas,
          ),
        ),
      ]),
      filter(([, imagesDisplayed]) => imagesDisplayed),
      exhaustMap(([, , dateRange, recognitions, cache]) =>
        this.doEnsureCache(DashboardCacheType.recognitionAreas, () =>
          this.dashboardMng.ensureRecognitionAreasCache(
            dateRange,
            recognitions,
            cache,
          ),
        ),
      ),
    );
  });

  toggleImagesDisplay$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardActions.toggleDevice,
        DashboardActions.toggleImagesDisplay,
      ),
      concatLatestFrom(() => [
        this.store.select(dashboardFeature.selectImagesDisplayed),
        this.store.select(dashboardFeature.isAnyCameraSelected),
      ]),
      filter(
        ([, imagesDisplayed, camerasSelected]) =>
          imagesDisplayed && !camerasSelected,
      ),
      map(() => DashboardActions.toggleImagesDisplay()),
    );
  });

  cacheUpdated$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.cacheUpdated),
      map((action) => {
        switch (action.key) {
          case DashboardCacheType.images:
          case DashboardCacheType.recognitionAreas:
            return DashboardActions.refreshCameraImages();
          default:
            return DashboardActions.regenerateChart();
        }
      }),
    );
  });

  regenerateChart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardActions.regenerateChart,
        DashboardActions.toggleDevice,
        DashboardActions.toggleVariable,
        DashboardActions.focusDevice,
        NodesActions.nodeUpdated,
      ),
      concatLatestFrom(() => [
        this.store.select(dashboardFeature.selectDashboardState),
        this.store.select(adminFeature.selectVariables),
      ]),
      map(([, dashboardState, hostVariables]) =>
        DashboardActions.regenerateChartSuccess({
          result: this.dashboardMng.generateChart(
            dashboardState,
            hostVariables,
          ),
        }),
      ),
    );
  });

  userSettingsChangeSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.userSettingsChangeSuccess),
      delay(1000),
      map(() => DashboardActions.refresh()),
    );
  });

  refresh$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.refresh),
      concatLatestFrom(() =>
        this.store.select(dashboardFeature.selectDateRange),
      ),
      map(([, dateRange]) => DashboardActions.dateRangeChanged({ dateRange })),
    );
  });

  refreshCameraImages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardActions.refreshCameraImages,
        DashboardActions.toggleDevice,
        DashboardActions.chartHover,
      ),
      concatLatestFrom(() => [
        this.store.select(dashboardFeature.selectDashboardState),
      ]),
      switchMap(([, dashboardState]) =>
        from(this.dashboardMng.getCameraImagesToDisplay(dashboardState)).pipe(
          map((images) =>
            DashboardActions.refreshCameraImagesSuccess({ images }),
          ),
        ),
      ),
    );
  });

  chartUomConfigChanged$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.chartUomConfigChanged),
      map(() => DashboardActions.regenerateChart()),
    );
  });

  // TODO: can lead to multiple chart redraw and even extra API calls, refactor this
  applySavedConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.applySavedConfig),
      map((action) =>
        DashboardActions.dateRangeChanged({
          dateRange:
            dateRanges.find(
              (dr) => dr.name === action.savedConfig.dateRangeName,
            ) ?? dateRanges[0],
        }),
      ),
    );
  });

  restoreSavedConfigs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.navigateToApp),
      map(() => localStorage.getItem(this.savedConfigsPersistentKey)),
      filter((savedConfigsRaw) => !!savedConfigsRaw),
      map((savedConfigsRaw) =>
        DashboardActions.restoreSavedConfigs({
          savedConfigs: JSON.parse(savedConfigsRaw ?? ''),
        }),
      ),
    );
  });

  persistSavedConfigs$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          DashboardActions.saveConfig,
          DashboardActions.deleteSavedConfig,
          DashboardActions.setDefaultConfig,
          DashboardActions.unsetDefaultConfig,
        ),
        concatLatestFrom(() => [
          this.store.select(dashboardFeature.selectSavedConfigs),
        ]),
        tap(([, savedConfigs]) =>
          localStorage.setItem(
            this.savedConfigsPersistentKey,
            JSON.stringify(savedConfigs),
          ),
        ),
      );
    },
    { dispatch: false },
  );

  // TODO: fix double readings loadings in some cases
  applyDefaultConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.activeLocationConfigRetrived),
      concatLatestFrom(() => [
        this.store.select(adminFeature.selectActiveLocation),
        this.store.select(dashboardFeature.selectSavedConfigs),
      ]),
      map(([, activeLocation, savedConfigs]) =>
        savedConfigs.find(
          (c) =>
            c.locationId === activeLocation!.location!.central_id && c.default,
        ),
      ),
      filter((defaultConfig) => !!defaultConfig),
      map((defaultConfig) =>
        DashboardActions.applySavedConfig({
          savedConfig: defaultConfig!,
        }),
      ),
    );
  });

  chartTooltip$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DashboardActions.chartHover),
        filter((action) => !!action.timestamp),
        concatLatestFrom(() => [
          this.store.select(dashboardFeature.selectChartData),
        ]),
        tap(([action, chartData]) =>
          this.dashboardMng.generateTooltips(
            action.timestamp!,
            action.clientX,
            action.clientY,
            chartData.data.datasets,
          ),
        ),
      );
    },
    { dispatch: false },
  );

  automaticallySelectCameraRecognition$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DashboardActions.toggleDevice),
        concatLatestFrom((action) => [
          this.store.select(dashboardFeature.selectDashboardState),
          this.store.select(
            adminFeature.selectDeviceSettings(action.device.thermal_camera_id),
          ),
        ]),
        filter(
          ([action, state, settings]) =>
            state.devices.some(
              (d) => d.thermal_camera_id === action.device.thermal_camera_id,
            ) && !!settings?.flower_recognition,
        ),
        tap(([action, state]) =>
          this.dashboardMng.ensureCameraRecognitionsPreselected(
            action.device,
            state,
          ),
        ),
      );
    },
    { dispatch: false },
  );

  shiftDateRange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.shiftDateRange),
      concatLatestFrom(() =>
        this.store.select(dashboardFeature.selectDateRange),
      ),
      map(([action, dateRange]) => {
        const currentStart = dateRange.start();
        const currentEnd = dateRange.end();
        const deltaMS =
          action.delta *
          Math.round(currentEnd.diff(currentStart, 'days', true));

        const newStart = currentStart.add(deltaMS, 'days');
        const newEnd = currentEnd.add(deltaMS, 'days');

        const now = dayjs();
        return newStart.isBefore(now) && newEnd.isBefore(now)
          ? { start: newStart, end: newEnd }
          : undefined;
      }),
      filter((newRange) => !!newRange),
      map((newRange) =>
        DashboardActions.dateRangeChanged({
          dateRange: DateRange.fromStartEnd(newRange!.start, newRange!.end),
        }),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store,
    private dashboardMng: DashboardService,
  ) {}

  private doEnsureCache(
    type: DashboardCacheType,
    ensureFunction: () => Promise<unknown>,
  ) {
    return from(ensureFunction()).pipe(
      map((cache) =>
        DashboardActions.cacheUpdated({
          key: type,
          value: cache,
        }),
      ),
    );
  }
}
