import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin } from 'rxjs';
import { delay, exhaustMap, filter, map, switchMap, tap } from 'rxjs/operators';
import {
  CSVExportRequest,
  CameraApi,
  DataApi,
  Email,
  ILocationsData,
  SaveCamPointPostRequest,
  SaveCamPointPutRequest,
  SetLocationConfiguration,
  UserApi,
  UserSettingsPut,
  VariableConfiguration,
} from '../../api/api-sdk';
import { TaskStatus } from '../../model/task';
import { adminFeature } from './feature';

import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { AdminActions } from './actions';

@Injectable()
export class AdminEffects {
  private readonly activeLocationPersistentKey = 'sigrow:activeLocation';

  loadLocations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.initAdmin),
      exhaustMap(() =>
        this.dataApi
          .locationsRetrieve(undefined)
          .pipe(
            map((res) =>
              AdminActions.locationsUpdated({ locations: res.locations }),
            ),
          ),
      ),
    );
  });

  loadDevices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.initAdmin),
      exhaustMap(() =>
        this.dataApi
          .camsRetrieve()
          .pipe(
            map((res) => AdminActions.devicesUpdated({ devices: res.cams })),
          ),
      ),
    );
  });

  loadTimezones$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.initAdmin),
      switchMap(() =>
        this.userApi
          .timezonesList()
          .pipe(
            map((timezones) => AdminActions.timezonesUpdated({ timezones })),
          ),
      ),
    );
  });

  loadUserSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.initAdmin),
      switchMap(() =>
        this.userApi
          .settingsRetrieve()
          .pipe(
            map((settings) =>
              AdminActions.userSettingsRetriveSuccess({ settings }),
            ),
          ),
      ),
    );
  });

  loadApiKey$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.initAdmin),
      switchMap(() =>
        this.userApi
          .apiKeyRetrieve()
          .pipe(
            map((res) =>
              AdminActions.apiKeyRetriveSuccess({ apiKey: res.api_key }),
            ),
          ),
      ),
    );
  });

  loadDevicePoints$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.devicesUpdated),
      concatLatestFrom(() => this.store.select(adminFeature.selectAllDevices)),
      exhaustMap(([, devices]) =>
        forkJoin(
          devices.map((d) =>
            this.cameraApi.currentCamPointsRetrieve(d.thermal_camera_id),
          ),
        ).pipe(
          map((res) => res.map((r) => r.points).flat()),
          map((points) => AdminActions.devicesPointsUpdated({ points })),
        ),
      ),
    );
  });

  loadVariablesForLocation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.activeLocationChanged),
      exhaustMap((action) =>
        this.userApi.variablesDataRetrieve(action.location.central_id).pipe(
          map((res) =>
            AdminActions.variablesUpdated({
              variables: res.variablesToDisplay,
            }),
          ),
        ),
      ),
    );
  });

  variableConfigUpdated$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AdminActions.variableConfigUpdated),
        concatLatestFrom(() =>
          this.store.select(adminFeature.selectActiveLocation),
        ),
        exhaustMap(([action, activeLocation]) =>
          this.userApi.variableConfigurationCreate(
            activeLocation!.location!.central_id,
            new VariableConfiguration({
              var_name: action.variable.name,
              max_rec_threshold: action.variable.max_rec,
              max_threshold: action.variable.max,
              min_rec_threshold: action.variable.min_rec,
              min_threshold: action.variable.min,
            }),
          ),
        ),
      );
    },
    { dispatch: false },
  );

  addDevicePoint$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.addDevicePoint),
      exhaustMap((action) =>
        this.cameraApi
          .camPointCreate(
            action.cameraId,
            new SaveCamPointPostRequest(action.point),
          )
          .pipe(
            map((res) =>
              AdminActions.devicePointAddSuccess({ point: res.point! }),
            ),
          ),
      ),
    );
  });

  updateDevicePoint$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.updateDevicePoint),
      exhaustMap((action) =>
        this.cameraApi
          .camPointUpdate(
            action.cameraId,
            new SaveCamPointPutRequest(action.point),
          )
          .pipe(
            map((res) =>
              AdminActions.devicePointUpdateSuccess({ point: res.point! }),
            ),
          ),
      ),
    );
  });

  startExportTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.startExportTask),
      concatLatestFrom(() =>
        this.store.select(adminFeature.selectActiveLocation),
      ),
      exhaustMap(([action, activeLocation]) =>
        this.dataApi
          .csvExportCreate(
            activeLocation!.location!.central_id!,
            new CSVExportRequest(action.params),
          )
          .pipe(
            map((res) =>
              AdminActions.exportTaskStarted({
                task: {
                  id: res.task_uuid,
                  title: 'CSV Export',
                },
              }),
            ),
          ),
      ),
    );
  });

  exportTaskStarted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.exportTaskStarted),
      map(() => AdminActions.scheduleCheckExportTaskState()),
    );
  });

  exportTaskStateUpdated$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.exportTaskStateUpdated),
      filter((action) =>
        [
          TaskStatus.pending.toString(),
          TaskStatus.runnings.toString(),
        ].includes(action.state.status),
      ),
      map(() => AdminActions.scheduleCheckExportTaskState()),
    );
  });

  scheduleCheckExportTaskState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.scheduleCheckExportTaskState),
      delay(3000),
      map(() => AdminActions.checkExportTaskState()),
    );
  });

  checkExportTaskState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.checkExportTaskState),
      concatLatestFrom(() => this.store.select(adminFeature.selectExportTask)),
      filter(([, task]) => !!task?.id),
      switchMap(([, task]) =>
        this.dataApi
          .csvExportStatusRetrieve(task!.id)
          .pipe(map((state) => AdminActions.exportTaskStateUpdated({ state }))),
      ),
    );
  });

  reload$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AdminActions.reload),
        tap(() => location.reload()),
      );
    },
    { dispatch: false },
  );

  loadLocationConfiguration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.activeLocationChanged),
      exhaustMap((action) =>
        this.userApi
          .locationConfigurationRetrieve(action.location.central_id)
          .pipe(
            map((config) =>
              AdminActions.activeLocationConfigRetrived({ config }),
            ),
          ),
      ),
    );
  });

  locationConfigChanged$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.updateActiveLocationConfig),
      concatLatestFrom(() => [
        this.store.select(adminFeature.selectActiveLocation),
      ]),
      switchMap(([action, activeLocation]) => {
        const updatedConfig = {
          location_name: action.config.location_name!,
          nodemapx: action.config.nodemapx!,
          nodemapy: action.config.nodemapy!,
          location_alerts: action.config.location_alerts,
          location_timezone_id: action.config.location_timezone_id,
        };
        return this.userApi
          .locationConfigurationCreate(
            activeLocation!.location!.central_id,
            new SetLocationConfiguration(updatedConfig),
          )
          .pipe(
            map(() =>
              AdminActions.activeLocationConfigRetrived({
                config: { ...activeLocation!.configuration!, ...updatedConfig },
              }),
            ),
          );
      }),
    );
  });

  userSettingsChanged$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.userSettingsChanged),
      switchMap((action) =>
        this.userApi
          .settingsUpdate(new UserSettingsPut(action.settings))
          .pipe(map(() => AdminActions.userSettingsChangeSuccess())),
      ),
    );
  });

  regenerateApiKey$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.regenerateApiKey),
      switchMap(() =>
        this.userApi
          .apiKeyCreate()
          .pipe(
            map((res) =>
              AdminActions.apiKeyRetriveSuccess({ apiKey: res.api_key }),
            ),
          ),
      ),
    );
  });

  addEmailAlert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.addEmailAlert),
      concatLatestFrom(() =>
        this.store.select(adminFeature.selectActiveLocation),
      ),
      switchMap(([action, activeLocation]) =>
        this.userApi
          .alertEmailCreate(
            activeLocation!.location!.central_id,
            new Email({ email: action.emailAddress }),
          )
          .pipe(map((email) => AdminActions.alertEmailAddSuccess({ email }))),
      ),
    );
  });

  alertEmailRemoved$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AdminActions.alertEmailRemoved),
        concatLatestFrom(() =>
          this.store.select(adminFeature.selectActiveLocation),
        ),
        switchMap(([action, activeLocation]) =>
          this.userApi.alertEmailDestroy(
            activeLocation!.location!.central_id,
            action.email.email_id,
          ),
        ),
      );
    },
    { dispatch: false },
  );

  restoreActiveLocation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.locationsUpdated),
      concatLatestFrom(() =>
        this.store.select(adminFeature.selectActiveLocation),
      ),
      filter(([, activeLocation]) => !activeLocation),
      map(([action]) => {
        const cachedLocation = localStorage.getItem(
          this.activeLocationPersistentKey,
        );
        if (cachedLocation) {
          const activeLocation: ILocationsData = JSON.parse(cachedLocation);
          if (
            action.locations.some(
              (l) => l.central_id === activeLocation.central_id,
            )
          ) {
            return activeLocation;
          }
        }
        return action.locations[0];
      }),
      map((activeLocation) =>
        AdminActions.activeLocationRestored({ location: activeLocation }),
      ),
    );
  });

  activeLocationRestored$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.activeLocationRestored),
      map((action) =>
        AdminActions.activeLocationChanged({ location: action.location }),
      ),
    );
  });

  persistActiveLocation$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AdminActions.activeLocationChanged),
        concatLatestFrom(() => [
          this.store.select(adminFeature.selectActiveLocation),
        ]),
        tap(([, activeLocation]) =>
          localStorage.setItem(
            this.activeLocationPersistentKey,
            JSON.stringify(activeLocation!.location),
          ),
        ),
      );
    },
    { dispatch: false },
  );

  // TODO: refactor this
  setLanguage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          AdminActions.userSettingsRetriveSuccess,
          AdminActions.userSettingsChanged,
        ),
        concatLatestFrom(() =>
          this.store.select(adminFeature.selectUserSettings),
        ),
        switchMap(([, userSettings]) =>
          this.translate
            .use(userSettings.language)
            .pipe(tap(() => dayjs.locale(userSettings.language))),
        ),
      );
    },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private dataApi: DataApi,
    private cameraApi: CameraApi,
    private userApi: UserApi,
    private translate: TranslateService,
  ) {}
}
