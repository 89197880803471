import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, filter, map, switchMap, tap } from 'rxjs';
import { AdminActions } from './../../../../state/admin/actions';

import { adminFeature } from '../../../../state/admin/feature';
import { AuthActions } from '../../../../state/auth/actions';
import { DashboardActions } from '../../dashboard/state/dashboard.actions';
import { NodesService } from '../services/nodes.service';
import {
  DataApi,
  SetLocationNodeData,
  UserApi,
} from './../../../../api/api-sdk';
import { NodesActions } from './nodes.actions';
import { nodesFeature } from './nodes.feature';

@Injectable()
export class NodesEffects {
  private readonly nodesOrderPersistentKey = 'sigrow:nodesOrder';

  loadNodesData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        AdminActions.activeLocationConfigRetrived,
        NodesActions.updateNodesData,
      ),
      concatLatestFrom(() =>
        this.store.select(adminFeature.selectActiveLocation),
      ),
      filter((values) => values.every((v) => !!v)),
      switchMap(([, activeLocation]) =>
        this.dataApi
          .nodesDataRetrieve(activeLocation!.location!.central_id)
          .pipe(
            map((res) =>
              NodesActions.nodesDataUpdated({
                nodesData: res.nodes_data,
              }),
            ),
          ),
      ),
    );
  });

  nodesDataDisplay$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        NodesActions.nodeUpdated,
        NodesActions.nodesDataUpdated,
        NodesActions.toggleAttribute,
        AdminActions.variableConfigUpdated,
      ),
      concatLatestFrom(() => [
        this.store.select(nodesFeature.selectNodesState),
        this.store.select(adminFeature.selectAllDevices),
      ]),
      map(([, state, devices]) =>
        NodesActions.nodesAndAlertsUpdated({
          ...this.nodesMng.getNodesAndAlerts(state, devices),
        }),
      ),
    );
  });

  readingColumns$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.variablesUpdated),
      switchMap((action) => this.nodesMng.getReadingColumns(action.variables)),
      map((columns) => NodesActions.readingColumnsUpdated({ columns })),
    );
  });

  nodeUpdated$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NodesActions.nodeUpdated),
        concatLatestFrom(() => [
          this.store.select(adminFeature.selectActiveLocation),
          this.store.select(nodesFeature.selectNodesData),
        ]),
        switchMap(([action, activeLocation, nodesData]) => {
          const node = nodesData.find((nd) => nd.remote_id === action.nodeId);
          if (!activeLocation || !node) {
            return EMPTY;
          }
          return this.userApi.locationNodeDataCreate(
            activeLocation.location!.central_id,
            new SetLocationNodeData({
              node_name: node.node_name,
              remote_id: node.remote_id,
              status: node.status,
              visible: node.visible,
              physical_location: node.physical_location,
              position: node.position,
              node_alerts: node.node_alerts,
            }),
          );
        }),
      );
    },
    { dispatch: false },
  );

  openChartsForNode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NodesActions.openChartsForNode),
      concatLatestFrom(() => [
        this.store.select(adminFeature.selectDevicesForActiveLocation),
        this.store.select(adminFeature.selectVariables),
      ]),
      map(([action, devices, variables]) =>
        DashboardActions.focusDevice({
          device: devices.find((d) => d.remote_id === action.node.id)!,
          variables: [variables.find((v) => v.name === 'temp')!],
        }),
      ),
    );
  });

  restoreNodesOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.navigateToApp),
      map(() => localStorage.getItem(this.nodesOrderPersistentKey)),
      filter((nodesOrder) => !!nodesOrder),
      map((nodesOrder) =>
        NodesActions.restoreCustomNodesOrder({ ...JSON.parse(nodesOrder!) }),
      ),
    );
  });

  persistSavedConfigs$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          NodesActions.setCustomNodesOrder,
          NodesActions.setNodesSortField,
        ),
        concatLatestFrom(() => [
          this.store.select(nodesFeature.selectNodesOrder),
          this.store.select(nodesFeature.selectNodesSortField),
        ]),
        tap(([, nodesOrder, nodesSortField]) =>
          localStorage.setItem(
            this.nodesOrderPersistentKey,
            JSON.stringify({ nodesOrder, nodesSortField }),
          ),
        ),
      );
    },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private dataApi: DataApi,
    private userApi: UserApi,
    private nodesMng: NodesService,
  ) {}
}
