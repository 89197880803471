import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColDef } from 'ag-grid-community';
import dayjs from 'dayjs';
import _, { isNumber } from 'lodash';
import { lastValueFrom, take } from 'rxjs';
import { IVariableToDisplay } from '../../../../api/api-sdk';
import { BaseDashboardService } from '../../../../common/base.dashboard.service';
import { AlertType } from '../../../../model/alert';
import { IConfiguredDevice } from '../../../../model/dashboard';
import {
  IColorRange,
  INodeListItem,
  IVariableAlert,
} from '../../../../model/node';
import { INodesState } from '../state/nodes.feature';

@Injectable({
  providedIn: 'root',
})
export class NodesService extends BaseDashboardService {
  constructor(private translate: TranslateService) {
    super();
  }

  getNodesAndAlerts(state: INodesState, devices: IConfiguredDevice[]) {
    const nodes: INodeListItem[] = [];
    const alerts = new Map<string, AlertType>();

    for (const node of state.nodesData) {
      const device = devices.find((d) => d.remote_id === node.remote_id);
      const item: INodeListItem = {
        id: node.remote_id,
        name: node.node_name,
        date: dayjs.unix(node.timestamp_unix),
        cameraId: device?.thermal_camera_id,
        lastReading: device ? dayjs.unix(device.timestamp_unix) : undefined,
        attributes: [],
        alerts: [],
      };

      const attrs = _(state.attrGroups)
        .map((g) => g.attributes)
        .flatten()
        .value();

      for (const attr of attrs) {
        const value = node[attr.valuePath];
        const itemAttribute = {
          ...attr,
          value,
          alertType: isNumber(value)
            ? isNumber(attr?.alertMaxValue) && value >= attr?.alertMaxValue
              ? AlertType.high
              : isNumber(attr?.alertMinValue) && value <= attr.alertMinValue
                ? AlertType.low
                : AlertType.unknown
            : AlertType.unknown,
        };

        if (itemAttribute.alertType !== AlertType.unknown) {
          if (!item.alerts.includes(itemAttribute.alertType)) {
            item.alerts.push(itemAttribute.alertType);
          }
          alerts.set(attr.id, itemAttribute.alertType);
        }

        if (attr.selected) {
          item.attributes.push(itemAttribute);
        }
      }

      nodes.push(item);
    }
    return {
      nodes,
      alerts: Array.from(alerts).map(
        ([id, alertType]) => ({ id, alertType }) satisfies IVariableAlert,
      ),
    };
  }

  async getReadingColumns(variables: IVariableToDisplay[]) {
    const staticColumns: ColDef[] = [
      {
        field: 'remote_id',
        headerName: await this.getString('remoteIDdevice'),
      },
      {
        field: 'node_name',
        headerName: await this.getString('nodeName'),
      },
      { field: 'date' },
    ];

    const variableColumns: ColDef[] = [];

    for (const variable of variables) {
      const headerName = await this.getString(variable.name);
      variableColumns.push({
        field: variable.name,
        headerName,
        cellStyle: (params: {
          column: { getColId: () => string | number };
          value: number;
        }) => {
          const colorRange = this.getColorRange(
            params.column.getColId().toString(),
            params.value,
          );
          return colorRange
            ? {
                backgroundColor: colorRange.backgroundColor,
                color: colorRange.textColor ?? 'unset',
              }
            : undefined;
        },
      });
    }

    return [...staticColumns, ...variableColumns];
  }

  private getColorRange(varId: string, value: number) {
    if (value) {
      const colorSettings = this.defaultNodeDataColors[varId] ?? [];
      for (const colorRange of colorSettings) {
        if (colorRange.less && value <= colorRange.less) {
          return colorRange;
        } else if (
          colorRange.from &&
          colorRange.to &&
          value > colorRange.from &&
          value <= colorRange.to
        ) {
          return colorRange;
        } else if (colorRange.greater && value > colorRange.greater) {
          return colorRange;
        }
      }
    }

    return undefined;
  }

  private async getString(key: string) {
    return await lastValueFrom(this.translate.get(key).pipe(take(1)));
  }

  // TODO: refactor this
  private defaultNodeDataColors: { [id: string]: IColorRange[] } = {
    par: [
      { less: 0, backgroundColor: '#285172', textColor: '#fff' },
      { from: 0, to: 500, backgroundColor: '#276fa8', textColor: '#fff' },
      { greater: 500, backgroundColor: '#0091db', textColor: '#fff' },
    ],
    temp: [
      { less: 15, backgroundColor: '#285172', textColor: '#fff' },
      { from: 15, to: 35, backgroundColor: '#276fa8', textColor: '#fff' },
      { from: 35, to: 45, backgroundColor: '#0091db', textColor: '#fff' },
      { greater: 45, backgroundColor: '#eaf2f2' },
    ],
    humid: [
      { less: 50, backgroundColor: '#eaf2f2' },
      { from: 50, to: 60, backgroundColor: '#5cc3f7' },
      { from: 60, to: 90, backgroundColor: '#0091db', textColor: '#fff' },
      { from: 90, to: 95, backgroundColor: '#276fa8', textColor: '#fff' },
      { greater: 95, backgroundColor: '#285172', textColor: '#fff' },
    ],
    co2: [
      { less: 200, backgroundColor: '#eaf2f2' },
      { from: 200, to: 300, backgroundColor: '#5cc3f7' },
      { from: 300, to: 400, backgroundColor: '#0091db', textColor: '#fff' },
      { greater: 400, backgroundColor: '#276fa8', textColor: '#fff' },
    ],
    soil_hum: [
      { less: 15, backgroundColor: '#eaf2f2' },
      { from: 15, to: 90, backgroundColor: '#0091db', textColor: '#fff' },
      { greater: 90, backgroundColor: '#285172', textColor: '#fff' },
    ],
    dli: [
      { less: 10, backgroundColor: '#eaf2f2' },
      { from: 10, to: 20, backgroundColor: '#5cc3f7' },
      { from: 20, to: 30, backgroundColor: '#0091db', textColor: '#fff' },
      { from: 30, to: 40, backgroundColor: '#276fa8', textColor: '#fff' },
      { greater: 50, backgroundColor: '#285172', textColor: '#fff' },
    ],
    pore_ec: [
      { less: 0.5, backgroundColor: '#eaf2f2' },
      { from: 0.5, to: 1.1, backgroundColor: '#5cc3f7' },
      { from: 1.1, to: 1.7, backgroundColor: '#0091db', textColor: '#fff' },
      { from: 1.7, to: 3, backgroundColor: '#276fa8', textColor: '#fff' },
      { greater: 3, backgroundColor: '#285172', textColor: '#fff' },
    ],
    soil_temp: [
      { less: 5, backgroundColor: '#eaf2f2' },
      { from: 5, to: 10, backgroundColor: '#5cc3f7' },
      { from: 10, to: 15, backgroundColor: '#0091db', textColor: '#fff' },
      { from: 15, to: 25, backgroundColor: '#276fa8', textColor: '#fff' },
      { greater: 25, backgroundColor: '#285172', textColor: '#fff' },
    ],
    t_dew: [
      { less: 10, backgroundColor: '#eaf2f2' },
      { from: 10, to: 18, backgroundColor: '#5cc3f7' },
      { from: 18, to: 26, backgroundColor: '#0091db', textColor: '#fff' },
      { from: 26, to: 40, backgroundColor: '#276fa8', textColor: '#fff' },
      { greater: 40, backgroundColor: '#285172', textColor: '#fff' },
    ],
    t_leaf: [
      { less: 10, backgroundColor: '#eaf2f2' },
      { from: 10, to: 18, backgroundColor: '#5cc3f7' },
      { from: 18, to: 26, backgroundColor: '#0091db', textColor: '#fff' },
      { from: 26, to: 40, backgroundColor: '#276fa8', textColor: '#fff' },
      { greater: 40, backgroundColor: '#285172', textColor: '#fff' },
    ],
    vbat: [
      { less: 25, backgroundColor: '#eaf2f2' },
      { from: 25, to: 50, backgroundColor: '#5cc3f7' },
      { from: 50, to: 75, backgroundColor: '#0091db', textColor: '#fff' },
      { from: 75, to: 100, backgroundColor: '#276fa8', textColor: '#fff' },
      { greater: 100, backgroundColor: '#285172', textColor: '#fff' },
    ],
    vpd: [
      { less: 0.3, backgroundColor: '#eaf2f2' },
      { from: 0.3, to: 0.7, backgroundColor: '#5cc3f7' },
      { from: 0.7, to: 1.1, backgroundColor: '#0091db', textColor: '#fff' },
      { from: 1.1, to: 2, backgroundColor: '#276fa8', textColor: '#fff' },
      { greater: 2, backgroundColor: '#285172', textColor: '#fff' },
    ],
  };
}
