/* eslint-disable @typescript-eslint/no-explicit-any */

import { Injectable } from '@angular/core';
import { ChartConfiguration, Point, ScriptableContext } from 'chart.js';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import dayjs from 'dayjs';
import _, { chunk, get, isNumber, round } from 'lodash';
import { lastValueFrom } from 'rxjs';
import {
  GetCamPointsPoints,
  GetCamRecognitionsAggregatedRecognitions,
  GetCamRecognitionsRecognition,
  GetCamSerieEnrichedItem,
  GetCamSerieItem,
  GetCamSerieRequest,
  IGetCurrentCamPoint,
  IVariableToDisplay,
  LocationChartAllVarsDataset,
} from '../../../../api/api-sdk';
import { BaseDashboardService } from '../../../../common/base.dashboard.service';
import { AlertType } from '../../../../model/alert';
import { CameraViewType, ICameraImage } from '../../../../model/camera';
import {
  CameraVariableType,
  DashboardCacheType,
  IChartData,
  IChartLegendDataset,
  IChartMetadata,
  IChartUomConfig,
  IDevice,
  ITooltip,
  RecognitionType,
  Uom,
  recognitionVariables,
} from '../../../../model/dashboard';
import { DateRange } from '../../../../model/dateRange';
import { DeviceUtils } from '../../../../utils/device';
import { DashboardActions } from '../state/dashboard.actions';
import { IDashboardState } from '../state/dashboard.feature';
import {
  ICameraRecognitionConfig,
  IChartLegendDatasetGroup,
  IChartLegendItem,
  IChatGenerationResult,
  ICustomPoint,
  ISigrowChartConfiguration,
  SigrowDataset,
  VariableDisplayName,
} from './../../../../model/dashboard';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends BaseDashboardService {
  generateChart(
    state: IDashboardState,
    hostVariables: IVariableToDisplay[],
  ): IChatGenerationResult {
    this.colorsMap = state.chartColorsMap;

    const data = this.getEmptyChartData();
    const options = this.getEmptyChartOptions(state.dateRange);

    // 1. Generate chart datasets from all available data sources

    this.drawVariables(state, data, hostVariables);
    this.drawPoints(state, data);
    this.drawRecognitions(state, data);
    this.drawCustomPoints(state, data);

    // 2. Get unique list of uoms from generated chart datasets

    const uoms = _(data.datasets)
      .map((ds) => ds.uom)
      .uniq()
      .value();

    // 3. Configure Y axis - group by uom and apply min/max limits

    this.configureYAxis(state.chartUomConfigs, data, options, uoms);

    // 4. Generate chart metadata to be used in UI templates

    const metadata: IChartMetadata = {
      legend: uoms.map((uom) => ({
        uom,
        datasetGroups: _(data.datasets)
          .filter((ds) => ds.uom === uom)
          .map(
            (ds) =>
              ({
                name: ds.label!,
                variable: ds.variableName,
                color: ds.borderColor!.toString(),
              }) satisfies IChartLegendDataset,
          )
          .groupBy((ds) => ds.variable)
          .map(
            (group, key) =>
              ({
                variable: key,
                datasets: group,
              }) satisfies IChartLegendDatasetGroup,
          )
          .value(),
      })),
    };

    // 5. Add night ranges

    // TODO: temp try/catch until we fully agree requirements here
    try {
      options!.plugins!.annotation = {
        annotations: chunk(this.getNightRanges(state), 2).map(
          (range) =>
            ({
              drawTime: 'beforeDatasetsDraw',
              type: 'box',
              xMin: range[0],
              xMax: range[range.length - 1],
              borderColor: 'transparent',
              backgroundColor: this.addAlpha('#EEEEEE', 0.4),
            }) satisfies AnnotationOptions,
        ),
      };
      options!.plugins!.zoom = {
        pan: {
          enabled: true,
          modifierKey: 'ctrl',
        },
        zoom: {
          drag: {
            enabled: true,
          },
          mode: 'xy',
        },
      };
    } catch (err) {
      console.error(err);
    }

    return { data: { data, options, metadata }, colorsMap: this.colorsMap };
  }

  updateChartLegendItemConfig(
    chartData: IChartData,
    legendItem: IChartLegendItem,
    config: IChartUomConfig,
  ): IChartData {
    const yAxisScale = _.get(chartData.options?.scales, legendItem.uom);
    if (yAxisScale) {
      yAxisScale.min = config.minValue;
      yAxisScale.max = config.maxValue;
    }
    return chartData;
  }

  async getCameraImagesToDisplay(state: IDashboardState) {
    const imagesCache: Map<string, GetCamSerieEnrichedItem[]> = state.cache.get(
      DashboardCacheType.images,
    );

    if (!imagesCache?.size) {
      return [];
    }

    const recognitionsCache: Map<string, GetCamRecognitionsRecognition[]> =
      state.cache.get(DashboardCacheType.recognitionAreas);

    const imageTimestamp = state.cameraImagesTimestamp
      ? dayjs(state.cameraImagesTimestamp).format('YYYYMMDDHHmmss')
      : undefined;

    const res: ICameraImage[] = [];

    for (const camera of DeviceUtils.applyCamerasFilter(state.devices)) {
      const cameraCacheKey = this.getCameraDataCacheKey(
        camera.thermal_camera_id,
        state.dateRange,
      );

      const images = imagesCache.get(cameraCacheKey);

      if (!images?.length) {
        continue;
      }

      let imageToDisplay: GetCamSerieEnrichedItem | undefined;

      if (imageTimestamp) {
        imageToDisplay = this.getFrameByTimestamp(images, imageTimestamp);
      }

      imageToDisplay ??= _.last(images);

      if (!imageToDisplay?.date) {
        continue;
      }

      const recognitionData = this.getFrameByTimestamp(
        recognitionsCache?.get(cameraCacheKey) ?? [],
        imageToDisplay.date,
      );

      const recognitionConfig = state.recognitions.filter(
        (r) => r.cameraId === camera.thermal_camera_id,
      );

      if (imageToDisplay['temperature+']) {
        res.push({
          id: imageToDisplay.id,
          camera,
          viewType: CameraViewType.TemperaturePlus,
          imageUrl: imageToDisplay['temperature+'],
          timestamp: imageToDisplay.date,
          recognitionConfig,
          recognitionData,
        });
      }
      if (imageToDisplay['stomata+']) {
        res.push({
          id: imageToDisplay.id,
          camera,
          viewType: CameraViewType.StomataPlus,
          imageUrl: imageToDisplay['stomata+'],
          timestamp: imageToDisplay.date,
          recognitionConfig,
          recognitionData,
        });
      }
    }

    return res;
  }

  async ensurePointsCache(
    dateRange: DateRange,
    points: IGetCurrentCamPoint[],
    cache: Map<string, GetCamPointsPoints>,
  ) {
    cache ??= new Map();

    const { date_begin, date_end } = this.getApiDateRange(dateRange);
    const cameraIds = new Set(points.map((p) => p.thermal_cam_id));

    for (const cameraId of cameraIds) {
      const cacheKey = this.getCameraDataCacheKey(cameraId, dateRange);

      let points = cache.get(cacheKey);

      if (!points) {
        points = (
          await lastValueFrom(
            this.cameraApi.camPointsRetrieve(
              cameraId,
              date_begin,
              date_end,
              false,
            ),
          )
        ).points.points;
        cache.set(cacheKey, points);
      }
    }

    return cache;
  }

  async ensureCustomPointsCache(
    dateRange: DateRange,
    customPoints: ICustomPoint[],
    cache: Map<string, GetCamSerieItem[]>,
  ) {
    cache ??= new Map();

    const { date_begin, date_end } = this.getApiDateRange(dateRange);

    for (const customPoint of customPoints) {
      const cacheKey = this.getCustomPointCacheKey(customPoint, dateRange);

      let customPoints = cache.get(cacheKey);

      if (!customPoints) {
        customPoints = (
          await lastValueFrom(
            this.cameraApi.camSerieCreate(
              customPoint.cameraId,
              new GetCamSerieRequest({
                date_begin,
                date_end,
                positions: [[customPoint.serverX, customPoint.serverY]],
              }),
            ),
          )
        ).serie;
        cache.set(cacheKey, customPoints);
      }
    }

    return cache;
  }

  async ensureRecognitionReadingsCache(
    dateRange: DateRange,
    recognitions: ICameraRecognitionConfig[],
    cache: Map<string, GetCamRecognitionsAggregatedRecognitions[]>,
  ) {
    cache ??= new Map();

    const { date_begin, date_end } = this.getApiDateRange(dateRange);
    const cameraIds = new Set(recognitions.map((r) => r.cameraId));

    for (const cameraId of cameraIds) {
      const cacheKey = this.getCameraDataCacheKey(cameraId, dateRange);

      let recognitions = cache.get(cacheKey);

      if (!recognitions) {
        recognitions = (
          await lastValueFrom(
            this.cameraApi.camRecognitionsAggregatedRetrieve(
              cameraId,
              date_begin,
              date_end,
            ),
          )
        ).recognitions;
        cache.set(cacheKey, recognitions);
      }
    }

    return cache;
  }

  // TODO: refactor and merge with ensureRecognitionReadingsCache
  async ensureRecognitionAreasCache(
    dateRange: DateRange,
    recognitions: ICameraRecognitionConfig[],
    cache: Map<string, GetCamRecognitionsRecognition[]>,
  ) {
    cache ??= new Map();

    const { date_begin, date_end } = this.getApiDateRange(dateRange);
    const cameraIds = new Set(recognitions.map((r) => r.cameraId));

    for (const cameraId of cameraIds) {
      const cacheKey = this.getCameraDataCacheKey(cameraId, dateRange);

      let recognitions = cache.get(cacheKey);

      if (!recognitions) {
        recognitions = (
          await lastValueFrom(
            this.cameraApi.camRecognitionsRetrieve(
              cameraId,
              date_begin,
              date_end,
            ),
          )
        ).recognitions;
        cache.set(cacheKey, recognitions);
      }
    }

    return cache;
  }

  async ensureImagesCache(
    dateRange: DateRange,
    devices: IDevice[],
    cache: Map<string, GetCamSerieEnrichedItem[]>,
  ) {
    cache ??= new Map();

    const { date_begin, date_end } = this.getApiDateRange(dateRange);

    for (const camera of DeviceUtils.applyCamerasFilter(devices)) {
      const cacheKey = this.getCameraDataCacheKey(
        camera.thermal_camera_id,
        dateRange,
      );

      let images = cache.get(cacheKey);

      if (!images) {
        images =
          (
            await lastValueFrom(
              this.cameraApi.camSerieEnrichedCreate(
                camera.thermal_camera_id,
                new GetCamSerieRequest({
                  date_begin,
                  date_end,
                  positions: [],
                }),
              ),
            )
          ).serie ?? [];
        cache.set(cacheKey, images);
      }
    }

    return cache;
  }

  generateTooltips(
    timestamp: number,
    clientX: number,
    clientY: number,
    datasets: SigrowDataset[],
  ) {
    const chartCanvas = document.getElementById('chat-canvas');
    const tooltipElCursorHor = document.getElementById(
      'chartjs-tooltip-cursor-hor',
    );
    const tooltipElCursorVert = document.getElementById(
      'chartjs-tooltip-cursor-vert',
    );
    const tooltipEl = document.getElementById('chartjs-tooltip');

    if (
      !chartCanvas ||
      !tooltipElCursorHor ||
      !tooltipElCursorVert ||
      !tooltipEl
    ) {
      return;
    }

    const canvasPosition = chartCanvas.getBoundingClientRect();

    tooltipElCursorHor.style.left = `${clientX}px`;
    tooltipElCursorHor.style.top = `${canvasPosition.top}px`;
    tooltipElCursorHor.style.height = `${canvasPosition.height}px`;
    tooltipElCursorHor.style.pointerEvents = 'none';

    tooltipElCursorVert.style.left = `${canvasPosition.left}px`;
    tooltipElCursorVert.style.top = `${clientY}px`;
    tooltipElCursorVert.style.width = `${canvasPosition.width}px`;
    tooltipElCursorVert.style.pointerEvents = 'none';

    const offsetX = 20;
    const offsetY = 20;

    let left = clientX + offsetX;
    if (left + tooltipEl.offsetWidth >= window.visualViewport!.width) {
      left -= tooltipEl.offsetWidth + offsetX * 2;
    }

    let top = clientY + offsetY;
    if (top + tooltipEl.offsetHeight >= window.visualViewport!.height) {
      top -= tooltipEl.offsetHeight + offsetY * 2;
    }

    tooltipEl.style.left = `${left}px`;
    tooltipEl.style.top = `${top}px`;
    tooltipEl.style.pointerEvents = 'none';

    const tooltips: ITooltip[] = [];

    for (const dataset of datasets) {
      const matchingPoint = _(dataset.data as Point[])
        .orderBy((p) => p.x, 'desc')
        .filter((p) => p.x <= timestamp)
        .first();

      if (!matchingPoint) {
        continue;
      }

      tooltips.push({
        title: dataset.label!,
        uom: dataset.uom,
        variable: dataset.variableName,
        color: dataset.borderColor!.toString(),
        x: matchingPoint.x,
        y: round(matchingPoint.y, 2),
      });
    }

    this.store.dispatch(DashboardActions.updateCurrentTooltips({ tooltips }));
  }

  ensureCameraRecognitionsPreselected(device: IDevice, state: IDashboardState) {
    if (
      !state.recognitions.some((r) => r.cameraId === device.thermal_camera_id)
    ) {
      this.store.dispatch(
        DashboardActions.toggleRecognition({
          config: {
            cameraId: device.thermal_camera_id,
            recognitionType: RecognitionType.flowers,
          },
        }),
      );
    }

    if (!state.recognitionVariables.length) {
      this.store.dispatch(
        DashboardActions.toggleRecognitionVariable({
          variable: recognitionVariables[0],
        }),
      );
    }
  }

  private drawVariables(
    state: IDashboardState,
    data: ISigrowChartConfiguration['data'],
    hostVariables: IVariableToDisplay[],
  ) {
    const readingsCache: LocationChartAllVarsDataset[] = state.cache.get(
      DashboardCacheType.readings,
    );

    if (!readingsCache?.length) {
      return;
    }

    for (const device of DeviceUtils.applySensorsFilter(state.devices)) {
      for (const variable of state.variables) {
        const chartData: Point[] | undefined = readingsCache
          .find((ds) => ds.remote_id === device.remote_id)
          ?.data.map(
            (d) =>
              ({
                x: dayjs(d.x, 'YYYYMMDDHHmmss').valueOf(),
                y: isNumber(d[variable.name]) ? d[variable.name] : undefined,
              }) as Point,
          )
          .sort((p1, p2) => p1.x - p2.x);

        const dataset: SigrowDataset = {
          data: chartData ?? [],
          label: device.name,
          pointRadius: 0,
          backgroundColor: 'transparent',
          deviceId: device.remote_id,
          uom: variable.unit,
          variableName: variable.name,
        };

        data.datasets.push(dataset);

        const hostVariable = hostVariables.find(
          (hv) => hv.name === variable.name,
        );

        const getVariableAlertType = (value: number): AlertType => {
          if (isNumber(hostVariable?.max) && value > hostVariable!.max) {
            return AlertType.high;
          } else if (isNumber(hostVariable?.min) && value < hostVariable!.min) {
            return AlertType.low;
          } else {
            return AlertType.unknown;
          }
        };

        const getReadingColor = (value: number) => {
          switch (getVariableAlertType(value)) {
            case AlertType.high:
              return this.addAlpha('#EF9A9A', 0.5);
            case AlertType.low:
              return this.addAlpha('#90CAF9', 0.5);
            default:
              return color;
          }
        };

        const color = this.getDatasetColor(dataset);
        dataset.borderColor = color;

        if (!dataset.data.length) {
          continue;
        }

        dataset.pointBorderColor = (ctx: ScriptableContext<'line'>) =>
          getReadingColor(ctx.parsed?.y);
        dataset.pointBackgroundColor = (ctx: ScriptableContext<'line'>) =>
          getReadingColor(ctx.parsed?.y);
        dataset.pointStyle = 'circle';
        dataset.pointRadius = (ctx: ScriptableContext<'line'>) => {
          switch (getVariableAlertType(ctx.parsed?.y)) {
            case AlertType.high:
            case AlertType.low:
              return 5;
            default:
              return 0;
          }
        };
      }
    }
  }

  private drawPoints(
    state: IDashboardState,
    data: ISigrowChartConfiguration['data'],
  ) {
    const pointCache: Map<string, GetCamPointsPoints> = state.cache.get(
      DashboardCacheType.points,
    );

    if (!pointCache?.size || !state.pointVariables.length) {
      return;
    }

    for (const camera of DeviceUtils.applyCamerasFilter(state.devices)) {
      const cameraPointsReadings = pointCache.get(
        this.getCameraDataCacheKey(camera.thermal_camera_id, state.dateRange),
      );

      if (!cameraPointsReadings) {
        continue;
      }

      for (const activePoint of state.points.filter(
        (p) => p.thermal_cam_id == camera.thermal_camera_id,
      )) {
        for (const variable of state.pointVariables) {
          const chartData: Point[] | undefined = Object.entries(
            cameraPointsReadings[activePoint.id],
          )
            .map(
              ([key, value]) =>
                ({
                  x: dayjs(key, 'YYYYMMDDHHmmss').valueOf(),
                  y: get(value, variable.valuePath!),
                }) as Point,
            )
            .sort((p1, p2) => p1.x - p2.x);
          const dataset: SigrowDataset = {
            data: chartData,
            label: `${camera.thermal_camera_id} - ${activePoint.name || activePoint.id}`,
            borderWidth: 2,
            pointRadius: 0,
            backgroundColor: 'transparent',
            deviceId: activePoint.id,
            uom: variable.uom,
            variableName: variable.type,
          };
          this.assignDatasetColors(dataset);
          data.datasets.push(dataset);
        }
      }
    }
  }

  private drawRecognitions(
    state: IDashboardState,
    data: ISigrowChartConfiguration['data'],
  ) {
    const recognitionsCache: Map<
      string,
      GetCamRecognitionsAggregatedRecognitions[]
    > = state.cache.get(DashboardCacheType.recognitionReadings);

    if (!recognitionsCache?.size || !state.recognitionVariables.length) {
      return;
    }

    for (const camera of DeviceUtils.applyCamerasFilter(state.devices)) {
      const cameraRecognitions = recognitionsCache.get(
        this.getCameraDataCacheKey(camera.thermal_camera_id, state.dateRange),
      );

      if (!cameraRecognitions) {
        continue;
      }

      const valuesToDisplay = state.recognitionVariables.map((v) => v.type);
      const valueProjections = ['maximum', 'average', 'minimum'];

      for (const recogntion of state.recognitions.filter((r) => r.cameraId)) {
        for (const value of valuesToDisplay) {
          let valueIndex = 0;
          for (const valueProjection of valueProjections.map(
            (vp) => `${vp}_${value}`,
          )) {
            const chartData: Point[] | undefined = cameraRecognitions
              .map(
                (r) =>
                  ({
                    x: dayjs(r.date, 'YYYYMMDDHHmmss').valueOf(),
                    y: r[recogntion.recognitionType].summary[valueProjection],
                  }) as Point,
              )
              .sort((p1, p2) => p1.x - p2.x);

            const dataset: SigrowDataset = {
              data: chartData,
              label: `${camera.thermal_camera_id} - ${recogntion.recognitionType} ${valueProjection}`,
              borderWidth: 1,
              borderDash: [10, 2],
              pointRadius: 0,
              backgroundColor: 'transparent',
              deviceId: camera.thermal_camera_id,
              uom: value === CameraVariableType.vpd ? Uom.kPa : Uom.celsius,
              variableName:
                value === CameraVariableType.vpd
                  ? VariableDisplayName.vpd
                  : VariableDisplayName.temperature,
            };
            this.assignDatasetColors(dataset);

            if (valueIndex === 0) {
              dataset.backgroundColor = this.addAlpha(
                dataset.borderColor!.toString(),
                0.25,
              );
              dataset.fill = `+${valueProjections.length - 1}`;
            }

            if (
              valueIndex !== 0 &&
              valueIndex !== valueProjections.length - 1
            ) {
              dataset.borderWidth = 2;
              dataset.borderDash = undefined;
            }

            data.datasets.push(dataset);
            valueIndex++;
          }
        }
      }
    }
  }

  private drawCustomPoints(
    state: IDashboardState,
    data: ISigrowChartConfiguration['data'],
  ) {
    const customPointCache: Map<string, GetCamSerieItem[]> = state.cache.get(
      DashboardCacheType.customPoints,
    );

    if (!customPointCache?.size) {
      return;
    }

    const values = ['stomata', 'temperature'];

    for (const camera of DeviceUtils.applyCamerasFilter(state.devices)) {
      for (const customPoint of state.customPoints.filter(
        (p) => p.cameraId == camera.thermal_camera_id,
      )) {
        const cameraCustomPointsReadings = customPointCache.get(
          this.getCustomPointCacheKey(customPoint, state.dateRange),
        );

        if (!cameraCustomPointsReadings?.length) {
          continue;
        }

        for (const value of values) {
          const chartData: Point[] = cameraCustomPointsReadings
            .map(
              (reading) =>
                ({
                  x: dayjs(reading.date, 'YYYYMMDDHHmmss').valueOf(),
                  y: _.get(
                    reading,
                    `points.${customPoint.serverX}x${customPoint.serverY}.${value}`,
                  ),
                }) as Point,
            )
            .sort((p1, p2) => p1.x - p2.x);

          const dataset: SigrowDataset = {
            data: chartData,
            label: `${value} ${customPoint.clientX}x${customPoint.clientY}`,
            borderWidth: 2,
            borderDash: [10, 2],
            pointRadius: 0,
            backgroundColor: 'transparent',
            deviceId: customPoint.cameraId,
            uom: Uom.celsius,
            variableName: VariableDisplayName.temperature,
          };
          this.assignDatasetColors(dataset);

          data.datasets.push(dataset);
        }
      }
    }
  }

  private configureYAxis(
    chartUomConfigs: {
      [id: string]: IChartUomConfig;
    },
    data: ChartConfiguration['data'],
    options: ChartConfiguration['options'],
    uoms: string[],
  ) {
    if (!uoms?.length) {
      return;
    }

    const getAxisTitle = (text: string) => {
      const title = {
        display: true,
        text,
        font: {
          size: 14,
          weight: 600,
        },
      };
      return title;
    };

    const defaultUom = uoms[0];

    options!.scales!['y'] = {
      title: getAxisTitle(defaultUom),
      ticks: {
        callback: (value) => new Intl.NumberFormat('nl-NL').format(+value),
      },
      min: chartUomConfigs[defaultUom]?.minValue,
      max: chartUomConfigs[defaultUom]?.maxValue,
    };

    for (const uom of uoms.slice(1)) {
      options!.scales![uom] = {
        title: getAxisTitle(uom),
        min: chartUomConfigs[uom]?.minValue,
        max: chartUomConfigs[uom]?.maxValue,
        position: 'right',
      };
    }

    for (const dataset of (data.datasets as SigrowDataset[]).filter(
      (ds) => ds.uom !== defaultUom,
    )) {
      (dataset as any).yAxisID = dataset.uom;
    }
  }

  private getCameraDataCacheKey(cameraId: number, dateRange: DateRange) {
    const { date_begin, date_end } = this.getApiDateRange(dateRange);
    return [cameraId, date_begin, date_end].join('#');
  }

  private getCustomPointCacheKey(
    customPoint: ICustomPoint,
    dateRange: DateRange,
  ) {
    return [
      this.getCameraDataCacheKey(customPoint.cameraId, dateRange),
      customPoint.clientX,
      customPoint.clientY,
    ].join('#');
  }

  private getFrameByTimestamp<T extends { date?: string }>(
    items: T[],
    timestamp: string,
  ) {
    return _(items)
      .filter((i) => !!i.date && i.date <= timestamp)
      .last();
  }

  private getNightRanges(state: IDashboardState) {
    const readingsCache: LocationChartAllVarsDataset[] = state.cache.get(
      DashboardCacheType.readings,
    );

    if (!readingsCache?.length) {
      return;
    }

    const deviceId = Math.min(...readingsCache.map((rs) => rs.remote_id));

    const readings =
      readingsCache.find((ds) => ds.remote_id === deviceId)?.data ?? [];

    let inRange = false;
    const res: string[] = [];

    for (let index = readings.length - 1; index >= 0; index--) {
      const par = readings[index]['par'];
      if (par === 0) {
        const timestamp = readings[index].x;
        if (!inRange) {
          res.push(timestamp, timestamp);
          inRange = true;
        } else {
          res[res.length - 1] = timestamp;
        }
      } else if (inRange) {
        inRange = false;
      }
    }

    return res;
  }
}
