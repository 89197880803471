import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { switchMap, take } from 'rxjs';
import { API_BASE_URL } from '../api/api-sdk';
import { authFeature } from './../state/auth/feature';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private store: Store,
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    if (!req.url.startsWith(this.apiBaseUrl)) {
      return next.handle(req);
    }

    return this.store.select(authFeature.selectUser).pipe(
      take(1),
      switchMap((user) => {
        if (!user) {
          return next.handle(req);
        }

        const authReq = req.clone({
          headers: req.headers.set(
            'Authorization',
            `Bearer ${user.accessToken}`,
          ),
        });

        return next.handle(authReq);
      }),
    );
  }
}
